import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import {
    Row, Col, Table, Button, DatePicker,
    Select, Space, Pagination, Input, Modal, Form, Divider,
    Checkbox
} from "antd";
import { Link } from "react-router-dom";
import moment from "moment";
import CancelReasonModal from '../../common/cancel-model/cancel-model';
import {
    editBooking
} from "../../../services/booking.service";

const { Option } = Select;
const { RangePicker } = DatePicker;
const { Search } = Input;

const OrderTable = ({ editRow, bookings, total, getBookings, loading }) => {
    const [type, setType] = useState('date');
    const [startModel, setStartModel] = useState(false);
    const [endModel, setEndModel] = useState(false);
    const [userModel, setUserModel] = useState(false);
    const [extendModel, setExtendModel] = useState(false);
    const [selectBooking, setSelectBooking] = useState('');
    const [filterType, setFilter] = useState('upcoming');
    const history = useHistory()
    const [form] = Form.useForm();
    const formRef = useRef(null);
    const [endTripWarning, setEndTripWarning] = useState(false);
    const [endTripExtraObj, setEndTripExtraObj] = useState({ cost: 0, kms: 0 });
    const [cancelModel, setCancelModel] = useState(false);
    const [searchText, setSearchText] = useState('');
    const columns = [
        {
            title: 'Order ID',
            dataIndex: 'order_id',
            fixed: 'left',
            width: 150,
            render: (order_id, all) => (
                <Button type="link" onClick={() => {
                    setUserModel(true);
                    setSelectBooking(all)
                }}>
                    {order_id}</Button>
            )
        },
        // {
        //     title: 'Date',
        //     dataIndex: 'createdAt',
        //     render: date => moment(date).format('DD-MM-YYYY hh:mm A')
        // },
        {
            title: 'User',
            dataIndex: 'customer_name'
        },
        {
            title: 'User Details',
            dataIndex: 'customer_name',
            render: (bike, all) => (
                <><div>{all.customer_email}</div>
                    <div>{all.customer_phone}</div></>
            ),
            responsive: ['md', 'lg'],
        },
        {
            title: 'Bike',
            dataIndex: 'bike',
            render: (bike) => (
                <><div>{bike.bike_name}</div>
                    <div>{bike?.bike_number?.toUpperCase()}</div></>
            ),
        },
        {
            title: 'Pickup',
            dataIndex: 'from',
            render: (bike, all) => `${moment(all.pick).format('DD MMM YY hh:mm A')}`,
        },
        {
            title: 'Dropoff',
            dataIndex: 'from',
            render: (bike, all) => `${moment(all.drop).format('DD MMM YY hh:mm A')}`,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            responsive: ['md', 'lg'],
        },
        {
            title: 'Action',
            key: 'action',
            render: (data) => {
                if (data.status === 'pending') {
                    return <>
                        <Button type="link" onClick={() => {
                            setStartModel(true);
                            setSelectBooking(data)
                        }}>
                            Start Trip</Button>
                        <Button danger type="link" onClick={() => {
                            setCancelModel(true);
                            setSelectBooking(data)
                        }}>
                            Cancel Trip</Button>
                    </>
                }
                if (data.status === 'trip-started' || data.status === 'trip-extended') {
                    return <>
                        <Button danger type="link" onClick={() => {
                            setEndModel(true);
                            setSelectBooking(data)
                        }}>
                            End Trip</Button>
                        {/* <Button type="link" onClick={() => {
                            setExtendModel(true);
                            setSelectBooking(data)
                        }}>Extend</Button> */}
                    </>
                }
            },
        },
    ];
    const params = new URLSearchParams(history.location.search);

    const PickerWithType = ({ type, onChange }) => {
        const from = params.get('from');
        const to = params.get('to');
        if (type === 'range') {
            return <RangePicker onChange={onChange}
                defaultValue={[from && moment(from), to && moment(to)]} />
        }
        if (type === 'date') {
            return <DatePicker onChange={onChange} defaultValue={from && moment(from)} />
        };
    };

    const updateQuaryParams = async (name, value) => {

        if (name === 'page') {
            params.set("page", value)
        }
        if (name === 'date') {
            if (value.from) {
                const from = moment(value.from).format('YYYY-MM-DD');
                params.set("from", from)
            }
            if (value.to) {
                const to = moment(value.to).format('YYYY-MM-DD');
                params.set("to", to)
            }
        }
        if (name === 'status') {
            params.set("status", value)
            if (value === 'all')
                params.delete('status')
        }
        if (name === 'search') {
            params.set("search", value)
            params.set("page", 1)
        }
        history.push({ search: params.toString() })
    }

    const onTypeChange = async (value) => {
        setType(value);
        const from = params.get('from');
        const to = params.get('to');
        const dateObj = {
            from: '',
            to: ''
        };
        if (value === 'date') {
            dateObj.from = from;
        }
        if (value === 'range') {
            dateObj.from = from;
            dateObj.to = to;
        }
        await updateQuaryParams('date', dateObj)
        getBookings();
    }

    const onSearch = async (value) => {
        console.log(value)
        await updateQuaryParams('search', value)
        getBookings();
    };

    const onDataChange = async (date) => {
        const dateObj = {
            from: '',
            to: ''
        };
        if (type === 'date') {
            dateObj.from = date;
        }
        if (type === 'range') {
            dateObj.from = date[0];
            dateObj.to = date[1];
        }
        await updateQuaryParams('date', dateObj)
        getBookings();
    };

    const onPageChange = async (value) => {
        await updateQuaryParams('page', value)
        getBookings();
    };

    const onStatusChange = async (value) => {
        params.set('page', 1)
        await updateQuaryParams('status', value)
        getBookings();
    };

    const handleCancel = () => {
        form.resetFields();
        setStartModel(false)
        setEndModel(false)
        setExtendModel(false)
        setUserModel(false)
        setSelectBooking('');
        setEndTripWarning(false);
        setEndTripExtraObj({ cost: 0, kms: 0 })
        setCancelModel()
    }

    const startTrip = async (value) => {
        const { started_odo_number, started_time } = value;
        await editBooking({
            id: selectBooking._id,
            status: 'trip-started',
            started_odo_number,
            started_time: moment(started_time).format('DD-MM-YYYY hh:mm A')
        })
        handleCancel();
        getBookings();
    }

    const endTrip = async (value) => {
        const { ended_odo_number, ended_time } = value;
        await editBooking({
            id: selectBooking._id,
            status: 'trip-ended',
            ended_odo_number,
            ended_time: moment(ended_time).format('DD-MM-YYYY hh:mm A'),
            addtional_cost: endTripExtraObj
        })
        handleCancel();
        getBookings();
    }

    const cancelTrip = async (value) => {
        await editBooking({
            id: selectBooking._id,
            status: 'cancelled',
            cancel_reason: value.reason
        })
        handleCancel();
        getBookings();
    }

    const extendTrip = async (value) => {
        const { extend_time } = value;
        // await editBooking({
        //     id: selectBooking._id,
        //     extend_time: moment(extend_time).format('DD-MM-YYYY hh:mm A')
        // })
        handleCancel();
        getBookings();
    }

    const addFilter = async (type) => {
        setFilter(type)
        params.set('page', 1)
        setSearchText('')
        if (type !== 'more') {
            params.delete('from')
            params.delete('to')
            params.delete('search')
            if (type === 'upcoming') {
                params.set('status', 'upcoming')
            } else if (type === 'started') {
                params.set('status', 'trip-started')
            } else if (type === 'ended') {
                params.set('status', 'trip-ended')
            } else if (type === 'cancelled') {
                params.set('status', 'cancelled')
            } else if (type === 'today-drop') {
                params.set('status', 'today-drop')
                params.set('from', moment().format('YYYY-MM-DD'))
                params.set('to', moment().add(1, 'days').format('YYYY-MM-DD'))
            } else if (type === 'today-pick') {
                params.set('status', 'today-pick')
                params.set('from', moment().format('YYYY-MM-DD'))
                params.set('to', moment().add(1, 'days').format('YYYY-MM-DD'))
            }
            history.push({ search: params.toString() })
            getBookings();
        }
    }

    const handleEndOdoChange = (e) => {
        const { value: inputValue } = e.target;
        const total_kms = inputValue - selectBooking?.started_odo_number;
        const excess_kms = total_kms - selectBooking.kms_limit;
        if (excess_kms > 0 && total_kms >= 0) {
            setEndTripWarning(true);
            const excess_kms_charge = selectBooking.bike?.excess_kms_charge || 0;
            const cost = excess_kms * excess_kms_charge;
            setEndTripExtraObj({ cost, kms: excess_kms })
        } else if (total_kms >= 0) {
            setEndTripWarning(false)
            setEndTripExtraObj({ cost: 0, kms: 0 })
        }
        formRef.current.setFieldsValue({
            total_kms: total_kms,
        });
    };

    const validateCheckbox = (_, value) => {
        if (!value) {
            return Promise.reject(new Error('Please check the box'));
        }
        return Promise.resolve();
    };

    const handlePrint = () => {
        window.print();
    };
    useEffect(() => {
        addFilter('upcoming')
    }, []);
    return (
        <>
            <Row>
                <Col span={24}>
                    <div className="filter-btns">
                        <Button
                            type="primary"
                            onClick={() => { addFilter('upcoming') }}
                            disabled={filterType === 'upcoming'}
                        >
                            Upcoming
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => { addFilter('started') }}
                            disabled={filterType === 'started'}
                        >
                            Ride Started
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => { addFilter('ended') }}
                            disabled={filterType === 'ended'}
                        >
                            Ride Ended
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => { addFilter('today-pick') }}
                            disabled={filterType === 'today-pick'}
                        >
                            Today's Pick up
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => { addFilter('today-drop') }}
                            disabled={filterType === 'today-drop'}
                        >
                            Today's Dropoff
                        </Button>
                        <Button
                            type="danger"
                            onClick={() => { addFilter('cancelled') }}
                            disabled={filterType === 'cancelled'}
                        >
                            Cancelled
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => { addFilter('more') }}
                            disabled={filterType === 'more'}
                        >
                            More filter
                        </Button>
                    </div>
                    <br />
                    {
                        filterType === 'more' &&
                        <div className="filter-select">
                            <Select style={{ width: 120 }}
                                placeholder="Status"
                                onChange={onStatusChange}
                                defaultValue={params.get('status') || 'all'} >
                                <Option value="all">All</Option>
                                <Option value="pending">Pending</Option>
                                <Option value="trip-started">Started Trip</Option>
                                <Option value="trip-ended">Ended Trip</Option>
                            </Select>
                            <Select value={type} onChange={onTypeChange} style={{ width: 120 }}>
                                <Option value="range">Range</Option>
                                <Option value="date">Date</Option>
                            </Select>
                            <PickerWithType type={type} onChange={onDataChange} />
                            {/* <Search
                                    placeholder="search"
                                    onSearch={onSearch}
                                    style={{
                                        width: 200,
                                    }}
                                /> */}
                        </div>
                    }
                </Col>
                <Col span={24}>
                    <Search
                        placeholder="Search by name and number"
                        onSearch={(value) => onSearch(value)}
                        style={{
                            float: 'left',
                            width: '200px'
                        }}
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        allowClear
                    />
                    <Link to="/create-booking" >
                        <Button
                            type="primary"
                            style={{
                                marginBottom: 16,
                                float: 'right'
                            }}
                        >
                            Create Booking
                        </Button>
                    </Link>
                </Col>
                <Col span={24}>
                    Total count : {total}
                </Col>
                <Col span={24}>
                    <Table
                        dataSource={bookings}
                        columns={columns}
                        pagination={false}
                        loading={loading}
                        className="order-table"
                        scroll={{ x: 900 }}
                    />

                    {total ? <Pagination defaultCurrent={1}
                        total={parseInt(total)}
                        pageSize={10}
                        pageSizeOptions={[]}
                        onChange={onPageChange} disabled={loading} /> : ""}
                </Col>
            </Row>
            <Modal open={startModel}
                title={`Start trip of ${selectBooking ? selectBooking.customer_name : ''}`}
                width={500}
                onCancel={handleCancel}
                footer={[]}>
                <Row>
                    <Col>
                        <Form
                            name="started-trip"
                            form={form}
                            onFinish={startTrip}
                        >
                            <Form.Item
                                name="started_time"
                                label="Started Time"
                                rules={[{ required: true, message: 'Please input Started Time!' }]}
                            >
                                <DatePicker
                                    format="YYYY-MM-DD HH:mm a"
                                    showTime />
                            </Form.Item>
                            <Form.Item
                                name="started_odo_number"
                                label="Started Odo"
                                rules={[{ required: true, message: 'Please input bike Odo number!' }]}
                            >
                                <Input
                                    type="number"
                                />
                            </Form.Item>
                            <Form.Item >
                                <Space>
                                    <Button danger onClick={handleCancel}>Cancel</Button>
                                    <Button type="primary" htmlType="submit">Ok</Button>
                                </Space>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            </Modal>
            <Modal open={endModel}
                title={`End trip of ${selectBooking ? selectBooking.customer_name : ''}`}
                width={500}
                onCancel={handleCancel}
                footer={[]}>
                <Row>
                    <Divider plain>Start Detail</Divider>
                    <Col span={24}>
                        Started Time : <b>{selectBooking.started_time}</b>
                    </Col>
                    <Col span={24}>
                        Started Odo : <b>{selectBooking.started_odo_number} Kms</b>
                    </Col>
                    <Divider plain>End Detail</Divider>
                    <Col span={24}>
                        <Form
                            name="ended-trip"
                            form={form}
                            ref={formRef}
                            onFinish={endTrip}
                        >
                            <Form.Item
                                name="ended_time"
                                label="Ended Time"
                                rules={[{ required: true, message: 'Please input Ended Time!' }]}
                            >
                                <DatePicker format="YYYY-MM-DD HH:mm a"
                                    showTime />
                            </Form.Item>
                            <Form.Item
                                name="ended_odo_number"
                                label="Ended Odo"
                                rules={[{ required: true, message: 'Please input bike Odo number!' }]}
                            >
                                <Input
                                    type="number"
                                    onChange={handleEndOdoChange}
                                />
                            </Form.Item>
                            <Form.Item
                                name="total_kms"
                                label="Total Kms"
                                rules={[{ required: false }]}
                            >
                                <Input
                                    type="number"
                                    disabled={true}
                                    className="overide-disable"
                                />
                            </Form.Item>
                            {endTripWarning &&
                                <Form.Item
                                    name="end_check"
                                    valuePropName="checked"
                                    rules={[{ validator: validateCheckbox }]}
                                    shouldUpdate={(prevValues, currentValues) =>
                                        prevValues.remember !== currentValues.remember
                                    }
                                    dependencies={['ended_odo_number']}
                                >
                                    <Checkbox>
                                        Money collected, ready to proceed
                                        <Divider plain>Additional Cost info</Divider>
                                        <p> Kms cap exceeding the limit of <b>{selectBooking.kms_limit}</b> Kms!</p>
                                        <p> Total extra kms: <mark><b>{endTripExtraObj.kms}kms</b></mark></p>
                                        <p> Total extra cost:<mark><b>Rs.{endTripExtraObj.cost}</b></mark></p>
                                    </Checkbox>
                                </Form.Item>}
                            <Form.Item >
                                <Space>
                                    <Button danger onClick={handleCancel}>Cancel</Button>
                                    <Button type="primary" htmlType="submit">Ok</Button>
                                </Space>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            </Modal>
            <Modal open={extendModel}
                title={`Extend trip of ${selectBooking ? selectBooking.customer_name : ''}`}
                width={500}
                onCancel={handleCancel}
                footer={[]}>
                <Row>
                    <Col>
                        <Form
                            name="extend-trip"
                            form={form}
                            onFinish={extendTrip}
                        >
                            <Form.Item
                                name="extend_time"
                                label="Extend Drop Time"
                                rules={[{ required: true, message: 'Please input Extend Drop Time!' }]}
                            >
                                <DatePicker format="YYYY-MM-DD HH:mm a"
                                    showTime />
                            </Form.Item>
                            <Form.Item >
                                <Space>
                                    <Button danger onClick={handleCancel}>Cancel</Button>
                                    <Button type="primary" htmlType="submit">Ok</Button>
                                </Space>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            </Modal>
            <Modal open={userModel}
                title={`Booking detail of ${selectBooking ? selectBooking.customer_name : ''}`}
                width={600}
                onCancel={handleCancel}
                style={{ top: 20 }}
                footer={[<Button key="print" onClick={handlePrint}>
                    Print
                </Button>]}>
                <Row>
                    <Col span={12}><p>Order ID:{selectBooking.order_id}</p></Col>
                    <Col span={12}> <p>  Status:<mark> {selectBooking.status}</mark></p></Col>
                    {selectBooking.status === 'cancelled' &&
                        <Col span={24}>
                            <p>  Cancel Reason:<mark>{selectBooking.cancel_reason}</mark></p>
                        </Col>
                    }
                    <Col span={24} className="h-plr10">
                        <h3>Customer Details</h3>
                        <table className="complete-table">
                            <tr>
                                <td>Customer Name</td>
                                <td><b><mark>{selectBooking.customer_name}</mark></b></td>
                            </tr>
                            <tr>
                                <td>Phone</td>
                                <td>{selectBooking.customer_phone}</td>
                            </tr>
                            <tr>
                                <td>Alternative Phone</td>
                                <td>{selectBooking.customer_alt_phone}</td>
                            </tr>
                            <tr>
                                <td>Email</td>
                                <td>{selectBooking.customer_email}</td>
                            </tr>
                            <tr>
                                <td>DL No.</td>
                                <td>{selectBooking.customer_dl}</td>
                            </tr>
                        </table>
                        <br />
                    </Col>
                    <Col span={24} className="h-plr10">
                        <h3>Ride Details</h3>
                        <table className="complete-table">
                            <tr>
                                <td>Booked Date</td>
                                <td><b><mark>{selectBooking.createdAt && moment(selectBooking.createdAt).format('DD-MM-YYYY hh:mm A')}</mark></b></td>
                            </tr>
                            <tr>
                                <td>Pick Up Date & Time</td>
                                <td>{selectBooking.pick && moment(selectBooking.pick).format('DD-MM-YYYY hh:mm A')}</td>
                            </tr>
                            <tr>
                                <td>Drop Off Date & Time</td>
                                <td>{selectBooking.drop && moment(selectBooking.drop).format('DD-MM-YYYY hh:mm A')}</td>
                            </tr>
                            <tr>
                                <td>Bike</td>
                                <td>{selectBooking.bike && `${selectBooking.bike.bike_name}`}</td>
                            </tr>
                            <tr>
                                <td>Total Days</td>
                                <td><b><mark>{selectBooking.day && selectBooking.day > 1 ? `${selectBooking.day} days` : `${selectBooking.day} day`}</mark></b></td>
                            </tr>
                            <tr>
                                <td>Total Price</td>
                                <td><b><mark>{selectBooking.price && `Rs. ${selectBooking.price}`}</mark></b></td>
                            </tr>
                            <tr>
                                <td>Total Kms limit</td>
                                <td><b><mark>{selectBooking.kms_limit && `${selectBooking.kms_limit} Kms`}</mark></b></td>
                            </tr>
                            <tr>
                                <td>Start / ODO</td>
                                <td>
                                    <b>{selectBooking.started_time !== 'null' && selectBooking.started_time}</b> <br />
                                    {selectBooking.started_odo_number > 0 && `${selectBooking.started_odo_number} Kms`}
                                </td>
                            </tr>
                            <tr>
                                <td>End / ODO</td>
                                <td>
                                    <b>{selectBooking.ended_time !== 'null' && selectBooking.ended_time}</b> <br />
                                    {selectBooking.ended_odo_number > 0 && `${selectBooking.ended_odo_number} Kms`}</td>
                            </tr>
                            <tr>
                                <td>Additonal Charges</td>
                                <td>
                                    {selectBooking.addtional_cost &&
                                        <>
                                            <span>Extra Kms : {selectBooking.addtional_cost.kms} kms</span><br />
                                            <span>Extra Charges : Rs.{selectBooking.addtional_cost.cost}</span>
                                        </>
                                    }
                                </td>
                            </tr>
                        </table>
                        <br />
                        <h4>Bike details</h4>
                        <table className="complete-table">
                            <tr>
                                <th>Bike</th>
                                <th>Full Day</th>
                                <th>Half Day</th>
                                <th>Excess Kms Charge</th>
                            </tr>
                            <tr>
                                <td>{selectBooking.bike?.bike_name}</td>
                                <td>
                                    <div>{`Charge: Rs. ${selectBooking.bike ? selectBooking.bike?.full_day_rate : 0}`}</div>
                                    <div>{`Kms limit: ${selectBooking.bike ? selectBooking.bike?.full_day_limit : 0}`}</div>
                                </td>
                                <td>
                                    <div>{`Charge: Rs. ${selectBooking.bike ? selectBooking.bike?.half_day_rate : 0}`}</div>
                                    <div>{`Kms limit: ${selectBooking.bike ? selectBooking.bike?.half_day_limit : 0}`}</div>
                                </td>
                                <td>{`${selectBooking.bike ? selectBooking.bike?.excess_kms_charge : 0} Kms`}</td>
                            </tr>
                        </table>
                    </Col>
                </Row >
            </Modal>
            <CancelReasonModal
                visible={cancelModel}
                onCancel={handleCancel}
                onOk={cancelTrip}
            />
        </>)
}

const mapStateToProps = ({ bookingData: { bookings, total, loading } }) => {
    return {
        bookings,
        total,
        loading
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderTable); 
