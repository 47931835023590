import React, {  useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Row,
  Col
} from 'antd';
import "./bike-form.css";
import { connect } from "react-redux";
import {
  addBike,
  editBike
} from "../../../services/bike.service";
import { toast } from "react-toastify";

const formItemLayout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 14,
  },
};
const toastProps = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
}
const BikeForm = ({ type, onLoad, selectedBike, closeModel }) => {
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    console.log('Received values of form: ', values);
    const req = type === "Add" ? await addBike(values) : await editBike({ ...values, id: selectedBike._id });
    console.log(req, 'req')
    if (req.status === 200) {
      toast.success(req?.data?.message, toastProps);
      onReset();
      closeModel();
    } else if (req.status === 404) {
      toast.error(req?.data?.message || "Error while uploading", toastProps);
    } else {
      toast.error("Error while updating", toastProps);
    }
  };

  const onReset = () => {
    form.resetFields();
    onLoad();
  };

  async function handleAccountChange(value) {
    form.setFieldsValue({
      bike_name: value?.bike_name,
      bike_image: value?.bike_image,
      bike_number:value?.bike_number,
      full_day_limit: value?.full_day_limit,
      full_day_rate: value?.full_day_rate,
      half_day_rate: value?.half_day_rate,
      half_day_limit: value?.half_day_limit,
      excess_kms_charge: value?.excess_kms_charge,
    })
  }

  useEffect(() => {
    if (type === "Edit") {
      handleAccountChange(selectedBike)
    } else {
      form.resetFields();
    }
  }, [type, selectedBike]);

  return (
    <Row>
      <Col span={24}>
        <Form
          name="validate_other"
          {...formItemLayout}
          onFinish={onFinish}
          form={form}
        >
          <Form.Item
            name="bike_name"
            label="Bike Name"
            rules={[{ required: true, message: 'Please input Bike Name!', whitespace: true }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="bike_number"
            label="Bike Number"
            rules={[{ required: true, message: 'Please input Bike Number!', whitespace: true }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="bike_image"
            label="Bike Image"
            rules={[{ required: true, message: 'Please input image url!', whitespace: true }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="full_day_rate"
            label="Full Day Rate"
            rules={[{ required: true, message: 'Please input Full Day Rate!', }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="full_day_limit"
            label="Full Day Limit"
            rules={[{ required: true, message: 'Please input Full Day Limit!', }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="half_day_rate"
            label="Half Day Rate"
            rules={[{ required: true, message: 'Please input Half Day Rate!', }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="half_day_limit"
            label="Half Day Limit"
            rules={[{ required: true, message: 'Please input Half Day Limit!', }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="excess_kms_charge"
            label="Excess Kms Charge"
            rules={[{ required: true, message: 'Please input Excess Kms Charge!', }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            wrapperCol={{
              span: 3,
              offset: 10,
            }}
          >
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(BikeForm);
