import React from "react";
import { Row, Col, Table } from "antd";
import {
    dayCalculation_WOP,
    bikePriceCalc_WOP,
    bikeKmsLimitCalc_WOP,
    addMintues_WOP
} from "../../../helpers/calculation";
import { connect } from "react-redux";

const TotalCost = ({ all, drop, pick }) => {
    const dropAdd30 = addMintues_WOP(drop);
    const bookingDay = dayCalculation_WOP(pick, dropAdd30);
    const calculatedPrice = bikePriceCalc_WOP(all.full_day_rate, all.half_day_rate, bookingDay)
    const calculatedKmsLimit = bikeKmsLimitCalc_WOP(all.full_day_limit, all.half_day_limit, bookingDay)
    return <>
        <p className="h-p0 h-m0 mark">
            Charge: Rs.  <b>{calculatedPrice}</b>
        </p>
        <p className="h-p0 h-m0 mark">
            Kms limit: <b>{calculatedKmsLimit}</b> Kms
        </p>
    </>
}

const BikePriceTable = ({ dataSource, pick, drop, loading }) => {
    const columns = [
        {
            title: 'Name',
            dataIndex: 'bike_name',
        },
        {
            title: 'Image',
            dataIndex: 'bike_image',
            render: (data) => (
                <>
                    <img src={data} width={80} height={60} />
                </>
            ),
        },
        {
            title: 'Available',
            dataIndex: 'count'
        },
        {
            title: 'Total Cost',
            dataIndex: 'half_day_rate', 
            render: (half_day_rate, all) => (<TotalCost all={all} pick={pick} drop={drop} />),
        }
    ];
    const filterBike = dataSource.reduce((acc, obj) => {
        const { bike_name } = obj;
        const existingObj = acc.find(item => item.bike_name === bike_name);

        if (existingObj) {
            existingObj.count++;
        } else {
            acc.push({ ...obj, count: 1 });
        }

        return acc;
    }, []);
    filterBike.sort((a, b) => b.count - a.count);
    return (
        <Row>
            <Col span={24}>
                <Table
                    dataSource={filterBike}
                    columns={columns}
                    loading={loading} />
            </Col>
        </Row>)
}

const mapStateToProps = ({ timeRangeData: { pick, drop }, bikeData: { loading } }) => {
    return {
        pick: pick,
        drop: drop,
        loading: loading
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(BikePriceTable); 
