import React from "react";
import { NavLink } from "react-router-dom";
import { Row, Col, PageHeader } from "antd";
import logo from '../../../assets/image/PNG/symbol.png'
import { Button } from "antd";
import { PoweroffOutlined } from '@ant-design/icons';

const Header = () => {
    const logout = async () => {
        localStorage.clear();
        window.location = '/'
    }
    const Title = () => (<NavLink to="/"><img src={logo} alt="logo" width={50} /></NavLink>)

    const checkActive = (match, location) => {
        //some additional logic to verify you are in the home URI
        if (!location) return false;
        const { pathname } = location;
        console.log(pathname);
        return pathname === "/";
    }
    return (<Row>
        <Col span={24}>
            <PageHeader
                className="site-page-header"
                onBack={() => null}
                title={<Title />}
                backIcon={false}
                extra={[
                    <div className="topnav">
                        <NavLink to="/" activeClassName="active" isActive={checkActive}>Availability</NavLink>
                        <NavLink to="/bikes" activeClassName="active">Bikes</NavLink>
                        <NavLink to="/bookings" activeClassName="active">Booking</NavLink>
                        <NavLink to="/revenue" activeClassName="active">Revenue</NavLink>
                        <NavLink to="/create-booking" activeClassName="active">Create Booking</NavLink>
                        <Button type="primary" className="logout-btn" icon={<PoweroffOutlined />} onClick={() => logout()}>
                            <span className="label">Logout</span>
                        </Button>
                    </div>,
                ]}
            />
        </Col>
    </Row >)
}

export default Header;
