import moment from "moment";

function dayCal(pick, drop) {
  if (pick & drop) {
    function roundHalf(num) {
      return Math.ceil(num * 2) / 2;
    }
    const tempPick12am = moment(pick).set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });
    const tempDrop12am = moment(drop).set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });
    var results = enumerateDaysBetweenDates(tempPick12am, tempDrop12am);
    if (results.length <= 1) {
      const minutes = pick.diff(drop, "minutes");
      let day = roundHalf(minutes / 1440);
      if (day < 1) {
        day = 1;
      }
      return day;
    } else {
      const tempDrop9am = moment(drop).set({
        hour: 9,
        minute: 0,
        second: 0,
        millisecond: 0,
      });
      const _tempPick12am = moment(pick).set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      });
      const tempPickHour = pick.diff(_tempPick12am, "hours");
      const minutes = tempDrop12am.diff(pick, "minutes");
      const tempMinutes = drop.diff(tempDrop9am, "minutes");
      console.log(
        minutes,
        tempMinutes,
        drop.format(),
        tempDrop9am.format(),
        tempPickHour,
        "tempMinutes"
      );
      let day = roundHalf(minutes / 1440);

      if (tempMinutes > 0) {
        day = day + 0.5;
      }
      if (tempPickHour >= 12) {
        day = day + 0.5;
      }
      if (day < 1) {
        day = 1;
      }
      return day;
    }
  }
}
export const dayCalculation = async (pick, drop) => {
  // function roundHalf(num) {
  //   return Math.ceil(num * 2) / 2;
  // }
  // const minutes = drop.diff(pick, "minutes");
  // let day = roundHalf(minutes / 1440);
  // if (day < 1 && day > 0) {
  //   day = 1;
  // }
  // return day;
  return dayCal(pick, drop);
};

export const checkForDecimal = (num) => {
  return num % 1 != 0;
};

var enumerateDaysBetweenDates = function (startDate, endDate) {
  var now = startDate,
    dates = [];

  while (now.isSameOrBefore(endDate)) {
    dates.push(now.format("DD/MM/YYYY"));
    now.add(1, "days");
  }
  return dates;
};

export const bikePriceCalc = async (
  full_day_rate = 0,
  half_day_rate = 0,
  day = 0
) => {
  let price = 0;
  const isDayDecimal = checkForDecimal(day);
  if (isDayDecimal) {
    price = Math.floor(day) * full_day_rate + half_day_rate;
  } else {
    price = Math.floor(day) * full_day_rate;
  }
  return price;
};

export const bikeKmsLimitCalc = async (
  full_day_limit = 0,
  half_day_limit = 0,
  day = 0
) => {
  let kms_limit = 0;
  const isDayDecimal = checkForDecimal(day);
  if (isDayDecimal) {
    kms_limit = Math.floor(day) * full_day_limit + half_day_limit;
  } else {
    kms_limit = Math.floor(day) * full_day_limit;
  }
  return kms_limit;
};

export const addMintues = async (drop) => {
  return moment(drop).add(0, "minutes");
};

export const dayCalculation_WOP = (pick, drop) => {
  return pick && dayCal(pick, drop);
};

export const bikePriceCalc_WOP = (
  full_day_rate = 0,
  half_day_rate = 0,
  day = 0
) => {
  let price = 0;
  const isDayDecimal = checkForDecimal(day);
  if (isDayDecimal) {
    price = Math.floor(day) * full_day_rate + half_day_rate;
  } else {
    price = Math.floor(day) * full_day_rate;
  }
  return price;
};

export const bikeKmsLimitCalc_WOP = (
  full_day_limit = 0,
  half_day_limit = 0,
  day = 0
) => {
  let kms_limit = 0;
  const isDayDecimal = checkForDecimal(day);
  if (isDayDecimal) {
    kms_limit = Math.floor(day) * full_day_limit + half_day_limit;
  } else {
    kms_limit = Math.floor(day) * full_day_limit;
  }
  return kms_limit;
};

export const addMintues_WOP = (drop) => {
  return moment(drop).add(0, "minutes");
};

const range = (start, end) => {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
};
export const disabledDateTime = () => ({
  disabledHours: () => [1, 2, 3, 4, 5, 6, 7, 8, 22, 23, 24],
  disabledMinutes: () => range(31, 60),
});
