import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Row, Col, Table, Button, Input } from "antd";
import {
    CheckCircleFilled,
    CloseCircleFilled,
    ExclamationCircleFilled
} from "@ant-design/icons";
const { Search } = Input;

const BikeTable = ({ addRow, editRow, deleteRow, actionBikeRow, dataSource, bikeByStatus }) => {
    const [searchText, setSearchText] = useState('');
    const [filteredData, setFilteredData] = useState(dataSource);
    const uniqueBikeNames = [...new Set(dataSource.map(obj => obj.bike_name))];
    const { blocked, available, on_trip } = bikeByStatus;
    const getStatusIcon = (id) => {
        const findInBlocked = blocked.find(obj => obj._id === id);
        if (findInBlocked) {
            return <CloseCircleFilled style={{ color: 'red' }} />;
        } else {
            const findInAvailable = available.find(obj => obj._id === id);
            if (findInAvailable) {
                return <CheckCircleFilled style={{ color: 'green' }} />;
            } else {
                const findInOnTrip = on_trip.find(obj => obj._id === id);
                if (findInOnTrip) {
                    return <ExclamationCircleFilled style={{ color: '#bfbf07' }} />;
                } else {
                    return '-'
                }
            }
        }
    }
    const handleSearch = (value) => {
        const filtered = dataSource.filter((item) =>
            item.bike_number.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredData(filtered);
        setSearchText(value);
    };
    const access = localStorage.getItem('access');
    const columns = [
        {
            title: 'Name',
            render: (data) => {
                const statusIcon = getStatusIcon(data._id)
                return (<>
                    <span>{data.bike_name} {statusIcon}</span>
                </>);
            },
            filters: uniqueBikeNames.map(obj => ({ value: obj, text: obj })),
            filterSearch: true,
            filterMode: 'tree',
            onFilter: (value, record) => {
                console.log(value, record)
                return record.bike_name.startsWith(value)
            },
        },
        {
            title: 'No.',
            dataIndex: 'bike_number',
            render: (data) => (
                <>
                    {data.toUpperCase()}
                </>
            ),
        },
        {
            title: 'Image',
            dataIndex: 'bike_image',
            render: (data) => (
                <>
                    <img src={data} alt="bike_image" width={70} height={50} />
                </>
            ),
        },
        {
            title: 'Full Day Rate',
            dataIndex: 'full_day_rate',
            responsive: ['md', 'lg'],
        },
        {
            title: 'Full Day Limit',
            dataIndex: 'full_day_limit',
            responsive: ['md', 'lg'],
        },
        {
            title: 'Half Day Rate',
            dataIndex: 'half_day_rate',
            responsive: ['md', 'lg'],
        },
        {
            title: 'Half Day Limitt',
            dataIndex: 'half_day_limit',
            responsive: ['md', 'lg'],
        },
        {
            title: 'Excess Kms Charge',
            dataIndex: 'excess_kms_charge',
            responsive: ['md', 'lg'],
        },
        {
            title: 'Action',
            key: 'action',
            render: (data) => access === "admin" && (
                <>
                    <Button type="link" onClick={() => editRow(data)}>Edit</Button>
                    <Button danger type="link" onClick={() => deleteRow(data)} >
                        Delete
                    </Button>
                    <Button danger={data.isAvailable}
                        primary={!data.isAvailable} type="link"
                        onClick={() => actionBikeRow(data)} >
                        {data.isAvailable ? 'Block' : 'Unblock'}
                    </Button>
                </>
            ),
        },
    ];
    useEffect(() => {
        setFilteredData(dataSource)
    }, [dataSource]);
    return (
        <Row>
            <Col span={24}>
                <Search
                    placeholder="Search by number"
                    value={searchText}
                    onChange={(e) => handleSearch(e.target.value)}
                    style={{
                        float: 'left',
                        width: '200px'
                    }}
                />
                <Button
                    onClick={addRow}
                    type="primary"
                    style={{
                        marginBottom: 16,
                        float: 'right'
                    }}
                >
                    Add new
                </Button>
                <Table
                    dataSource={filteredData}
                    columns={columns}
                    className="bike-price-table" />
            </Col>
        </Row>)
}

const mapStateToProps = ({ bikeData: { bikeByStatus } }) => {
    return {
        bikeByStatus
    };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(BikeTable);  
