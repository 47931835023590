import { axiosInstance } from "./axios.service";
import { toast } from "react-toastify";

function handleResponse(response) {
  return response;
}
const toasterProps = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};
function handleError(error) {  
  if (error?.response?.status === 403) {
    toast.error('Login Session closed!', toasterProps);
    localStorage.clear();
    window.location = '/'
  }
  else if (error?.response?.data?.error) {
    toast.error(error?.response?.data?.error, toasterProps);
  }
  else if (error?.response?.data?.message) {
    toast.error(error?.response?.data?.message, toasterProps);
  } else {
    toast.error("Internal server error!", toasterProps);
  }
  return Promise.reject(error);
}

export const getAccess = async () => {
  return await axiosInstance.get("/users/access").then(handleResponse)
    .catch(handleError);
};

export const login = async (data) => {
  return await axiosInstance
    .post("users/login", data)
    .then(handleResponse)
    .catch(handleError);
};
 
