import { combineReducers } from "redux";

import { CommonReducer } from "./common.reducer";
import { BikeListReducer } from "./bike-list.reducer";
import { BookingListReducer } from "./booking-list.reducer";
import { SelectedTimeRangeReducer } from "./selected_time_range.reducer";

export const storeInitialState = {};

const reducer = combineReducers({
  commonData: CommonReducer,
  bikeData: BikeListReducer,
  bookingData: BookingListReducer,
  timeRangeData: SelectedTimeRangeReducer,
});

const rootReducer = (state, action) => {
  return reducer(state, action);
};

export default rootReducer;
