const initialState = {
  loading: false,
  bikes: [],
  bikeByStatus:{
    blocked:[],
    available:[],
    on_trip:[]
  },
  total: 0,
  page: 1,
  sort: "desc",
};
const BikeListReducer = (state = initialState, action) => {
  switch (action.type) {
    case "BIKE_LIST_LOADING_TRUE":
      return { ...state, loading: true };
    case "BIKE_LIST_LOADING_FALSE":
      return { ...state, loading: false };
    case "UPDATE_BIKE_LIST":
      return {
        ...state,
        bikes: [...action.data],
        // total: action.data.count,
        // page: parseInt(action.data.page),
        // sort: action.data.sort,
      };
      case "UPDATE_BIKE_BY_STATUS_LIST":
      return {
        ...state,
        bikeByStatus: action.data
      };
    default:
      return state;
  }
};

export { BikeListReducer };
