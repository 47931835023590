import React, { useState, useEffect } from "react";
import {
  Form,
  Select,
  Button,
  Row,
  Col,
  DatePicker,
  message
} from 'antd';

import "./bike-avail-from.css";
import { connect } from "react-redux";
import {
  getAvailableBikes
} from "../../../../services/bike.service";
import {
  dayCalculation,
  checkForDecimal,
  bikePriceCalc,
  bikeKmsLimitCalc,
  addMintues,
  disabledDateTime
} from "../../../../helpers/calculation";
import moment from "moment";
import {
  checkDatetime
} from "../../../../helpers/time";
import CustomDatetimePicker from '../../../common/date-time-picker/date-time-picker'

const { Option } = Select;

const showTimeProps = { format: 'YYYY-MM-DD hh:mm A', use12Hours: true, minuteStep: 30 };

const BikeAvailabilityFrom = ({ data, initialData, setData, bikes, setBikes }) => {

  const [dateTimeRange, setDateTimeRange] = useState({
    pick: '',
    drop: ''
  })
  const [selectedBike, setSelectedBike] = useState('')
  const [formData, setformData] = useState(initialData);

  const [form] = Form.useForm();

  const handleFromDataChang = (value) => {
    form.setFieldsValue({
      pick_up: value?.pick,
      drop: value?.drop,
      bike: value?.bike?._id
    })
    const bikeId = selectedBike ? selectedBike?._id : value?.bike?._id
    const _selectedBike = bikes.find(item => item._id === bikeId);
    setSelectedBike(_selectedBike)
    setDateTimeRange({ pick: value?.pick, drop: value?.drop })
  }

  const priceCalculation = async (full_day_rate = 0, half_day_rate = 0, day = 0) => {
    if (!full_day_rate && !half_day_rate && !day) {
      if (!full_day_rate && !half_day_rate) {
        message.error('Full day and Half day rate not valid!')
      } else if (!day) {
        message.error('Day is not valid! Should be greater than 0')
      }
      return null;
    } else {
      return bikePriceCalc(full_day_rate, half_day_rate, day)
    }
  }

  const kmsLimitCalculatiom = async (full_day_limit = 0, half_day_limit = 0, day = 0) => {
    if (!full_day_limit && !half_day_limit && !day) {
      if (!full_day_limit && !half_day_limit) {
        message.error('Full day and Half day limit not valid!')
      } else if (!day) {
        message.error('Day is not valid! Should be greater than 0')
      }
      return null;
    } else {
      return bikeKmsLimitCalc(full_day_limit, half_day_limit, day)
    }
  }

  const fetchBikeDetails = async () => {
    if (dateTimeRange.pick && dateTimeRange.drop) {
      const payload = {
        pick: moment(dateTimeRange.pick).format(),
        drop: moment(dateTimeRange.drop).format()
      }
      const availBikes = await getAvailableBikes(payload);
      const _bikes = availBikes?.data?.bikes
      if (_bikes.length <= 0) {
        message.warning('Bike not available on selected dates', 5)
      }
      setBikes(_bikes)
    }
  }

  const restFormData = () => {
    //setformData(initialData)
    setData(null)
  }

  const onChangePickUp = (date, dateString) => {
    setBikes([])
    setDateTimeRange({ ...dateTimeRange, pick: date })
    restFormData()
  };

  const onChangeDrop = (date, dateString) => {
    setBikes([])
    setDateTimeRange({ ...dateTimeRange, drop: date })
    restFormData()
  };

  const handleSelectChange = (value) => {
    const _selectedBike = bikes.find(item => item._id === value);
    console.log(`selected ${value}`, bikes, _selectedBike);
    setSelectedBike(_selectedBike)
    restFormData()
  };

  const updatePriceDetail = async () => {
    if (selectedBike && dateTimeRange) {
      const { full_day_rate, half_day_rate, full_day_limit, half_day_limit } = selectedBike;
      const { pick, drop } = dateTimeRange;
      if (full_day_rate && half_day_rate && pick && drop) {
        const dropAdd30 = await addMintues(drop);
        const bookingDay = await dayCalculation(pick, dropAdd30);
        const calculatedPrice = await priceCalculation(full_day_rate, half_day_rate, bookingDay)
        const calculatedKmsLimit = await kmsLimitCalculatiom(full_day_limit, half_day_limit, bookingDay)
        const _formData = {
          ...dateTimeRange,
          bike: selectedBike,
          day: bookingDay,
          price: calculatedPrice,
          kms_limit: calculatedKmsLimit,
        }
        setformData(_formData)
        setData(_formData)
        message.success('Detail updated!')
      }
    } else {
      if (!selectedBike) {
        message.error('Please reselect bike!')
        form.setFieldsValue({ bike: '' })
      }
      if (!dateTimeRange) {
        message.error('Please update date again!')
      }
    }
  }

  const onFinish = async (values) => {
    console.log(values)
    updatePriceDetail();
  };

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < moment().subtract(1, 'days').endOf('day');
  };

  const disabledDropDate = (current) => {
    // Can not select days before today and today
    const pick = dateTimeRange.pick || current;
    return current && current < moment(pick);
  };

  useEffect(() => {
    fetchBikeDetails();
  }, [dateTimeRange]);

  useEffect(() => {
    if (data) {
      setformData(data);
      handleFromDataChang(data);
    }
  }, [data]);

  const { pick, drop, bike, day, price, kms_limit } = formData;
  return (
    <Row>
      <Col lg={9} sm={24} className="h-plr20">
        <Form
          name="bike_avail"
          onFinish={onFinish}
          form={form}
          size={'large'}
        >
          <Form.Item
            name="pick_up"
            label="Pick Up"
            rules={[
              {
                validator: checkDatetime,
              },
            ]}
            // rules={[{
            //   required: true,
            //   type: "object",
            //   message: 'Please input Pick Up date time!',
            //   whitespace: true
            // }]}
          >
            <CustomDatetimePicker
              showTime={showTimeProps}
              onChange={onChangePickUp}
              disabledDate={disabledDate}
            />
            {/* <DatePicker
              showTime={showTimeProps}
              onChange={onChangePickUp}
              disabledDate={disabledDate} 
              format='DD-MM-YYYY hh:mm A'
            /> */}
          </Form.Item>
          <Form.Item
            name="drop"
            label="Drop Off"
            // rules={[{
            //   required: true,
            //   type: "object",
            //   message: 'Please input Pick Up date time!',
            //   whitespace: true
            // }]}
            rules={[
              {
                validator: checkDatetime,
              },
            ]}
          >
            <CustomDatetimePicker
              showTime={showTimeProps}
              onChange={onChangeDrop}
              disabledDate={disabledDropDate}
            />
            {/* <DatePicker
              showTime={showTimeProps}
              onChange={onChangeDrop}
              disabledDate={disabledDropDate} 
              format='DD-MM-YYYY hh:mm A'
            /> */}
          </Form.Item>
          <Form.Item
            name="bike"
            label="Bike"
            rules={[{ required: true, message: 'Please input Bike!', whitespace: true }]}
          >
            <Select placeholder="Please select a bike" onChange={handleSelectChange}>
              {
                bikes.map((item, idx) => <Option
                  value={item._id}
                  key={idx}>{`${item.bike_name} (${item.bike_number.toUpperCase()})`}
                </Option>)
              }
            </Select>
          </Form.Item>
          <Form.Item
            wrapperCol={{
              span: 3,
              offset: 10,
            }}
          >
            <Button type="primary" htmlType="submit">
              Get Price
            </Button>
          </Form.Item>
        </Form>
      </Col>
      <Col lg={15} sm={24} className="h-plr20">
        <h3>Booking details</h3>
        <table className="complete-table">
          <tr>
            <td>Pick Up Date & Time</td>
            <td>{pick && moment(pick).format('DD-MM-YYYY hh:mm A')}</td>
          </tr>
          <tr>
            <td>Drop Off Date & Time</td>
            <td>{drop && moment(drop).format('DD-MM-YYYY hh:mm A')}</td>
          </tr>
          <tr>
            <td>Bike</td>
            <td>{bike && `${bike.bike_name} (${bike.bike_number.toUpperCase()})`}</td>
          </tr>
          <tr>
            <td>Total Days</td>
            <td>{day && day > 1 ? `${day} days` : `${day} day`}</td>
          </tr>
          <tr>
            <td>Total Price</td>
            <td>{price && `Rs. ${price}`}</td>
          </tr>
          <tr>
            <td>Total Kms limit</td>
            <td>{kms_limit && `${kms_limit} Kms`}</td>
          </tr>
        </table>
        <br />
        <h3>Tariff details</h3>
        <table className="complete-table">
          <tr>
            <th>Bike</th>
            <th>Full Day</th>
            <th>Half Day</th>
            <th>Excess Kms Charge</th>
          </tr>
          <tr>
            <td>{bike?.bike_name}</td>
            <td>
              <div>{`Charge: Rs. ${bike ? bike?.full_day_rate : 0}`}</div>
              <div>{`Kms limit: ${bike ? bike?.full_day_limit : 0} Kms`}</div>
            </td>
            <td>
              <div>{`Charge: Rs. ${bike ? bike?.half_day_rate : 0}`}</div>
              <div>{`Kms limit: ${bike ? bike?.half_day_limit : 0} Kms`}</div>
            </td>
            <td>{`${bike ? bike?.excess_kms_charge : 0} Kms`}</td>
          </tr>
        </table>
      </Col>
    </Row >
  );
};

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(BikeAvailabilityFrom);
