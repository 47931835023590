import React from "react";
import { Redirect, Route } from "react-router";
// import { useSelector } from "react-redux"; 

import Header from "./components/common/header/header";

function PrivateRoute({ to, path, children }) {
  // const isAuth = useSelector((state) => state?.authReducer?.isAuth);
  const token = localStorage.getItem('token');
  const isAuth = token;
  if (isAuth) {
    return <Route exact path={path}>
      <Header />
      {children}
    </Route>
  } else {
    return <Redirect to={'/login'}></Redirect>
  }
}

export default PrivateRoute;
