import React, { useState } from "react";
import {
  Form,
  Button,
  message
} from 'antd';

import { connect } from "react-redux";
import {
  getAvailableBikes
} from "../../../services/bike.service";
import "./bike-avail-from.css";
import { fetchAvailableBike } from "../../../redux/action/bike/fetch-bike";
import { updateSelectedTimeRange } from "../../../redux/action/time_range/selected_time_range"
import CustomDatetimePicker from '../../common/date-time-picker/date-time-picker'
import {
  checkDatetime
} from "../../../helpers/time";
import moment from "moment";


const showTimeProps = { format: 'YYYY-MM-DD hh:mm A', use12Hours: true, minuteStep: 30 };

const BikeAvailabilityTableFrom = ({
  fetchAvailableBike,
  updateSelectedTimeRange }) => {

  const [dateTimeRange, setDateTimeRange] = useState({
    pick: '',
    drop: ''
  })

  const [form] = Form.useForm();

  const fetchBikeDetails = async () => {
    if (dateTimeRange.pick && dateTimeRange.drop) {
      const payload = {
        pick: moment(dateTimeRange.pick).format(),
        drop: moment(dateTimeRange.drop).format()
      }
      const availBikes = await getAvailableBikes(payload);
      const _bikes = availBikes?.data?.bikes
      if (_bikes.length <= 0) {
        message.warning('Bike not available on selected dates', 5)
      }
      fetchAvailableBike(_bikes)
      updateSelectedTimeRange(dateTimeRange)
    }
  }

  const onChangePickUp = (date, dateString) => {
    setDateTimeRange({ ...dateTimeRange, pick: date })
    fetchAvailableBike([])
  };

  const onChangeDrop = (date, dateString) => {
    setDateTimeRange({ ...dateTimeRange, drop: date })
  };

  const onFinish = async (values) => {
    console.log(values)
    fetchBikeDetails();
    message.success('Details updated', 5)
  };

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < moment().subtract(1, 'days').endOf('day');
  };

  const disabledDropDate = (current) => {
    const pick = dateTimeRange.pick || current;
    return current && current < moment(pick);
  };

  return (
    <Form
      name="bike_avail_table_form"
      className="bike_avail_table_form"
      onFinish={onFinish}
      form={form}
      size={'large'}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 18 }}
    >
      <Form.Item
        name="pick"
        label="Pick Up"
        rules={[
          {
            validator: checkDatetime,
          },
        ]}
      >
        <CustomDatetimePicker
          showTime={showTimeProps}
          onChange={onChangePickUp}
          disabledDate={disabledDate}
        />
      </Form.Item>
      <Form.Item
        name="drop"
        label="Drop Off"
        rules={[
          {
            validator: checkDatetime,
          },
        ]}
      >
        <CustomDatetimePicker
          showTime={showTimeProps}
          onChange={onChangeDrop}
          disabledDate={disabledDropDate}
        />
      </Form.Item>
      <Form.Item wrapperCol={{ span: 24 }}>
        <Button type="primary" htmlType="submit">
          Check Availability
        </Button>
      </Form.Item>
    </Form>
  );
};

const mapStateToProps = ({ bikeData: { bikes } }) => {
  return {
    bikes: bikes,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    fetchAvailableBike: (jobData) => dispatch(fetchAvailableBike(jobData)),
    updateSelectedTimeRange: (rangeData) => dispatch(updateSelectedTimeRange(rangeData))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BikeAvailabilityTableFrom);
