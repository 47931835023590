import React, { useState, useEffect } from "react";
import {
  Form,
  Select,
  Input,
  Button,
  Row,
  Col
} from 'antd';
import { connect } from "react-redux";

const { Option } = Select;
const formItemLayout = {
  labelCol: {
    span: 7,
  },
  wrapperCol: {
    span: 14,
  },
};
const CustomerForm = ({ setData, data }) => {
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    setData(values)
    console.log('Received values of form: ', values);
  };

  const handleFromDataChang = (value) => {
    form.setFieldsValue({
      customer_name: value?.customer_name,
      customer_phone: value?.customer_phone,
      customer_alt_phone: value?.customer_alt_phone,
      customer_email: value?.customer_email,
      customer_dl: value?.customer_dl
    })
  }

  useEffect(() => {
    if (data) {
      handleFromDataChang(data);
    }
  }, [data]);

  return (
    <Row>
      <Col span={14} offset={5}>
        <Form
          name="customer_form"
          {...formItemLayout}
          onFinish={onFinish}
          onValuesChange={() => setData(null)}
          form={form}
          size={'large'}
        >
          <Form.Item
            name="customer_name"
            label="Customer Name"
            rules={[{ required: true, message: 'Please input Customer Name!', whitespace: true }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="customer_phone"
            label="Phone"
            rules={[{ required: true, message: 'Please input Phone!', len: 10, whitespace: true }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="customer_alt_phone"
            label="Alternative Phone"
            rules={[{ required: true, message: 'Please input Phone!', len: 10, whitespace: true }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="customer_email"
            label="Email"
            rules={[{ required: true, message: 'Please input Email!', }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="customer_dl"
            label="DL No."
            rules={[{ required: true, message: 'Please input DL No.!', }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            wrapperCol={{
              span: 3,
              offset: 10,
            }}
          >
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerForm);
