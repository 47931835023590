import axios from "axios";

// const baseUrl = "https://api.royalcoorgbikes.com";
const baseUrl = "https://api.topgearbikerentals.com";
// const baseUrl = "http://localhost:3000/local";
const axiosInstance = axios.create({ baseURL: `${baseUrl}` });
axiosInstance.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  function (error) {
    console.log(`axiosInstance -> interceptors -> request -> error`, error);
    return Promise.reject(error);
  }
);

export { axiosInstance };
