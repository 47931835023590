const fetchAvailableBike = (data) => {
  return async (dispatch) => {
    dispatch({ type: "BIKE_LIST_LOADING_TRUE" });
    dispatch({
      type: "UPDATE_BIKE_LIST",
      data: data,
    });
    dispatch({ type: "BIKE_LIST_LOADING_FALSE" });
  };
};

const fetchBikesByStatus = (data) => {
  return async (dispatch) => { 
    dispatch({
      type: "UPDATE_BIKE_BY_STATUS_LIST",
      data: data,
    }); 
  };
};


export { fetchAvailableBike, fetchBikesByStatus };
