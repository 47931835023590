import React from "react";
import { connect } from "react-redux";
import { Row, Col } from "antd";
import moment from "moment";
import BikeAvailabilityTableFrom from "../../components/form/bike-avail-table-form/bike-avail-table-form";
import BikePriceTable from '../../components/table/bike-price-table/bike-price-table';
import {
  dayCalculation_WOP,
  addMintues_WOP
} from "../../helpers/calculation";

import "./landing.page.css";

const LandingPage = ({ bikes, pick, drop }) => {
  const dropAdd30 = addMintues_WOP(drop);
  const bookingDay = dayCalculation_WOP(pick, dropAdd30); 
  return (
    <>
      <Row>
        <Col className="h-plr20">
          <h3>Bike Availability</h3>
        </Col>
      </Row>
      <Row>
        <Col className="h-pt20"  xs={{ span: 22, offset: 1 }} lg={{ span: 8, offset: 8 }}>
          <BikeAvailabilityTableFrom />
        </Col>
      </Row>
      <Row className="h-pt10">
        <Col className="h-plr20" span={24}>
          <h4 className="h-fl h-pr20 h-pr20-sm">Pick Up : <b>{pick && moment(pick).format('DD-MM-YYYY hh:mm A')}</b></h4>
          <h4 className="h-fl h-plr20 h-pr20-sm">Drop Off : <b>{drop && moment(drop).format('DD-MM-YYYY hh:mm A')}</b></h4>
          <h4 className="h-fl h-plr20 h-pr20-sm">Total Days : <b>{bookingDay && (bookingDay > 1 ? `${bookingDay} days` : `${bookingDay} day`)} </b></h4>
        </Col>
        <Col className="h-plr20" span={24}>
          <BikePriceTable dataSource={bikes} />
        </Col>
      </Row >
    </>
  );
};

const mapStateToProps = ({ bikeData: { bikes }, timeRangeData: { pick, drop } }) => {
  return {
    bikes: bikes,
    pick: pick,
    drop: drop
  };
}; 
const mapDispatchToProps = (dispatch) => {
  return {  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LandingPage); 
