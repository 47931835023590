import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom"
import { Row, Col } from "antd";
import OrderTable from "../../components/table/order-table/order-table.jsx"
import {
  getBookingList
} from "../../services/booking.service";
import {
  fetchAvailableBooking,
  fetchAvailableBookingLoadingTrue
} from "../../redux/action/booking/fetch-booking"; 
import "./orders.page.css";

const OrderPage = ({ fetchAvailableBooking,
  fetchAvailableBookingLoadingTrue }) => {
  const history = useHistory()


  const getQueryParams = async () => {
    const params = new URLSearchParams(history.location.search);
    let page = params.get('page');
    let from = params.get('from');
    let to = params.get('to');
    const status = params.get('status');
    const search = params.get('search');
    if (!page) {
      params.append("page", 1)
      history.push({ search: params.toString() })
      page = 1;
    }
    // if (!from) {
    //   from = moment().format('YYYY-MM-DD');
    //   to = moment(from).add(1, 'days').format('YYYY-MM-DD');
    //   params.append("from", from)
    //   params.append("to", to)
    //   history.push({ search: params.toString() })
    // }
    return {
      page,
      from,
      to,
      status,
      search
    }
  }

  const getBookings = async () => {
    
    await fetchAvailableBookingLoadingTrue();
    const params = await getQueryParams();
    const _bookings = await getBookingList(params);
    const data = {
      bookings: _bookings?.data?.data,
      total: _bookings?.data?.total
    }
    await fetchAvailableBooking(data)
  }

  // useEffect(() => {
  //   getBookings();
  // }, []);

  return (
    <Row>
      <Col className="h-plr40 h-plr40-sm" span={24}>
        <h2>Bookings Listing</h2>
        <OrderTable
          getBookings={getBookings} />
      </Col>
    </Row>
  );
};

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchAvailableBooking: (bookingData) => dispatch(fetchAvailableBooking(bookingData)),
    fetchAvailableBookingLoadingTrue: () => dispatch(fetchAvailableBookingLoadingTrue())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderPage);
