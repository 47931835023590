// CancelReasonModal.js
import React from 'react';
import { Modal, Form, Input, Button } from 'antd';

const CancelReasonModal = ({ visible, onCancel, onOk }) => {
    const [form] = Form.useForm();

    const handleOk = () => {
        form
            .validateFields()
            .then((values) => {
                form.resetFields();
                onOk(values); // Pass the form values to the parent component
            })
            .catch((error) => {
                console.log('Validation error:', error);
            });
    };

    return (
        <Modal
            title="Cancel Order"
            open={visible}
            onCancel={onCancel}
            onOk={handleOk}
            width={400}
            closable={false}
        >
            <div className="z-ta-center">
                <h2>Are you sure?</h2>
                <p>You want to cancel the booking</p>
                <Form form={form}>
                    <Form.Item
                        name="reason"
                        label="Reason"
                        rules={[{ required: true, message: 'Please provide a reason' }]}
                    >
                        <Input.TextArea rows={4} placeholder="Enter your reason for canceling" />
                    </Form.Item>
                </Form>
            </div>
        </Modal>
    );
};

export default CancelReasonModal;
