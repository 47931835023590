const updateSelectedTimeRange = (data) => {
  return async (dispatch) => {
    dispatch({
      type: "UPDATE_SELECTED_TIME_RANGE",
      data: data,
    });
  };
};

export { updateSelectedTimeRange };
