import React from "react";
import { Route, Switch } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import PrivateRouteAdmin from "./PrivateRouteAdmin";

import LandingPage from "./pages/landing/landing.page";
import LoginPage from "./pages/login/login.page";
import BikesPage from "./pages/bikes/bikes.page";
import OrderPage from "./pages/orders/orders.page";
import CreateOrderPage from "./pages/create-order/create-order.page";
import RevenuePage from "./pages/revenue/revenue.page"

function AppRouter() {
  return (
    <Switch>
      <Route exact path="/login" component={LoginPage} />
      <PrivateRoute exact path="/">
        <LandingPage />
      </PrivateRoute>
      <PrivateRoute exact path="/bikes">
        <BikesPage />
      </PrivateRoute>
      <PrivateRoute exact path="/bookings">
        <OrderPage />
      </PrivateRoute>
      <PrivateRoute exact path="/create-booking">
        <CreateOrderPage />
      </PrivateRoute>
      <PrivateRouteAdmin exact path="/revenue">
        <RevenuePage />
      </PrivateRouteAdmin>
    </Switch>
  );
}

export default AppRouter;
