import React, { useState, useEffect } from 'react';
import { Column } from '@ant-design/plots';

const ColumnGraph = ({ data }) => {

    const config = {
        data,
        xField: 'month',
        yField: 'price',
        seriesField: 'month',
        legend: {
            position: 'top-left',
        },
    };
    return <Column {...config} />;
};
export default ColumnGraph;