import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Row, Col, Card, Modal } from "antd";
import BikeForm from "../../components/form/bike-form/bike-form";
import BikeTable from "../../components/table/bike-table/bike-table.jsx";
import BikeTripInfoHeader from "../../components/common/bike-trip-info-header/bike-trip-info-header"
import {
  getBikeList, deleteBike, editBike, getBikesByStatus
} from "../../services/bike.service";
import {
  fetchBikesByStatus
} from "../../redux/action/bike/fetch-bike";
import "./bikes.page.css";
import { toast } from "react-toastify";
const toastProps = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
}
const BikesPage = ({fetchBikesByStatus}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [type, setType] = useState('Add');
  const [bikes, setBikes] = useState([])
  const [selectedBike, setSelectedBike] = useState('');
  const [deleteAccount, setDeleteAccount] = useState('');
  const [actionBike, setActionBike] = useState('');
  const [actionModel, setActionModel] = useState(false);

  const addRow = () => {
    setType('Add')
    setIsModalVisible(true);
  }

  const editRow = (name) => {
    setSelectedBike(name)
    setType('Edit')
    setIsModalVisible(true);
  }

  const deleteRow = (name) => {
    setDeleteAccount(name)
    setIsDeleteModalVisible(true);
  }

  const actionBikeRow = (name) => {
    setActionBike(name)
    setActionModel(true);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedBike('')
  };

  const onLoad = async () => {
    const bikes = await getBikeList();
    setBikes(bikes?.data?.data);
    getBikeByStatus();
  }

  const handleDeleteOk = async () => {
    const req = await deleteBike(deleteAccount?._id);
    if (req.status === 200) {
      toast.success(req?.data?.message, toastProps);
      onLoad();
      setDeleteAccount('');
      setIsDeleteModalVisible(false);
    } else if (req.status === 404) {
      toast.error(req?.data?.message || "Error while uploading", toastProps);
    } else {
      toast.error("Error while updating", toastProps);
    }
  }

  const handleDeleteCancel = () => {
    setIsDeleteModalVisible(false);
    setDeleteAccount('')
  };

  const handleActionOk = async () => {
    const formData = { isAvailable: true, id: actionBike?._id }
    if (actionBike.isAvailable) {
      formData.isAvailable = false
    }
    const req = await editBike(formData)
    if (req.status === 200) {
      toast.success(req?.data?.message, toastProps);
      onLoad();
      setActionBike('');
      setActionModel(false);
    } else if (req.status === 404) {
      toast.error(req?.data?.message || "Error while uploading", toastProps);
    } else {
      toast.error("Error while updating", toastProps);
    }
  }

  const handleActionCancel = () => {
    setActionModel(false);
    setActionBike('')
  };

  const getBikeByStatus = async () => {
    const bikes = await getBikesByStatus();
    const data = bikes?.data?.data;
    await fetchBikesByStatus(data)
  }

  useEffect(() => {
    onLoad();
  }, []);
  console.log(bikes, 'bikes')
  return (
    <Row>
      <Col className="h-plr40 h-plr40-sm " span={24}>
        <h2>Bikes Listing</h2>
        <BikeTripInfoHeader />
        <BikeTable addRow={addRow} editRow={editRow} deleteRow={deleteRow} actionBikeRow={actionBikeRow} dataSource={bikes} />
      </Col>
      <Modal open={isModalVisible}
        title={`${type} Bike`}
        width={500}
        onCancel={handleCancel}
        footer={[]}>
        <BikeForm
          type={type}
          setType={setType}
          onLoad={onLoad}
          accounts={bikes}
          selectedBike={selectedBike}
          closeModel={setIsModalVisible} />
      </Modal>
      <Modal
        open={isDeleteModalVisible}
        onOk={handleDeleteOk}
        onCancel={handleDeleteCancel}
        width={300}
        closable={false}>
        <div className="z-ta-center">
          <h2>Are you sure?</h2>
          <p>You want to delete {deleteAccount?.bike_name}</p>
        </div>
      </Modal>
      <Modal
        open={actionModel}
        onOk={handleActionOk}
        onCancel={handleActionCancel}
        width={300}
        closable={false}>
        <div className="z-ta-center">
          <h2>Are you sure?</h2>
          <p>You want to {actionBike?.isAvailable} {actionBike?.isAvailable ? 'Block' : 'Unblock'}</p>
        </div>
      </Modal>
    </Row>
  );
};

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({ fetchBikesByStatus: (bikeData) => dispatch(fetchBikesByStatus(bikeData)) });

export default connect(mapStateToProps, mapDispatchToProps)(BikesPage);
