import React, { useState } from "react";
import { connect } from "react-redux";
import { Row, Col, Button, message, Steps, Modal } from "antd";

import BikeAvailabilityFrom from "../../components/form/create-order-from/bike-avail-from/bike-avail-from";
import CustomerForm from "../../components/form/create-order-from/customer-form/customer-form";
import CompleteTable from "../../components/table/complete-table/complete-table";
import "./create-order.page.css";
import bikeIcon from "../../assets/icon/png/bike-icon.png"
import userIcon from "../../assets/icon/png/user-icon.png"
import completeIcon from "../../assets/icon/png/complete-icon.png"
import {
  addBooking
} from "../../services/booking.service";
import moment from "moment";
import { toast } from "react-toastify";
const toastProps = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
}
const { Step } = Steps;

const initialRideData = {
  pick: '',
  drop: '',
  bike: '',
  day: 0,
  price: 0,
  kms_limit: 0,
}

const debounce = (func, delay) => {
  let timer;
  return function () {
    clearTimeout(timer);
    timer = setTimeout(func, delay);
  };
};

const CreateOrderPage = () => {

  const [current, setCurrent] = useState(0);
  const [bikes, setBikes] = useState([]);
  const [rideData, setRideData] = useState(null);
  const [customerData, setCustomerData] = useState(null);
  const [isConfirmModalVisible, setConfirmModalVisible] = useState(false);
  const [doubleClickDisabled, setDoubleClickDisabled] = useState(false);
  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const handleOk = debounce(async() => { 
      const formData = { ...rideData, ...customerData };
      formData.order_id = moment().valueOf();
      formData.bike = rideData?.bike?._id;
      formData.pick = moment(formData.pick).format();
      formData.drop = moment(formData.drop).format();
      const req = await addBooking(formData);
      // console.log(req, 'req')
      if (req.status === 200) {
        toast.success(req?.data?.message, toastProps);
        window.location = '/bookings'
      } else if (req.status === 404) {
        toast.error(req?.data?.message || "Error while uploading", toastProps);
      } else {
        toast.error("Error while updating", toastProps);
      }
      console.log('onOk action executed'); 
  })

  const handleConfirmOk = () => {
    if (!doubleClickDisabled) {
      setDoubleClickDisabled(true);
      handleOk();
    }
  };

  const handleConfirmCancel = () => {
    setConfirmModalVisible(false);
  };

  const steps = [
    {
      title: 'Availability',
      content: 'availability',
      icon: <img src={bikeIcon} width="25" alt='bikeicon' />,
      component: <BikeAvailabilityFrom
        setData={setRideData}
        data={rideData}
        initialData={initialRideData}
        bikes={bikes}
        setBikes={setBikes} />
    },
    {
      title: 'Customer Detail',
      content: 'customer-detail',
      icon: <img src={userIcon} width="20" />,
      component: <CustomerForm
        setData={setCustomerData}
        data={customerData} />
    },
    {
      title: 'Complete Order',
      content: 'complete',
      icon: <img src={completeIcon} width="20" />,
      component: <CompleteTable rideData={rideData} customerData={customerData} />
    },
  ];

  return (
    <Row>
      <Col className="h-plr40 h-plr40-sm h-pt10 h-mb15" span={24}>
        <h2 className="h-m0"><u>Create Booking</u></h2>
        <Steps current={current}>
          {steps.map((item) => (
            <Step key={item.title} title={item.title} icon={item.icon} />
          ))}
        </Steps>
        <div className="steps-content">{steps[current].component}</div>
        <div className="steps-action">
          {current < steps.length - 1 && (
            <Button type="primary" onClick={() => next()} className="h-fr"
              disabled={current === 0 && !rideData || current === 1 && !customerData}>
              Next
            </Button>
          )}
          {current === steps.length - 1 && (
            <Button type="primary" className="h-fr" onClick={() => setConfirmModalVisible(true)}>
              Done
            </Button>
          )}
          {current > 0 && (
            <Button
              style={{
                margin: '0 8px',
              }}
              onClick={() => prev()}
            >
              Previous
            </Button>
          )}
        </div>
      </Col>
      <Modal
        open={isConfirmModalVisible}
        onOk={handleConfirmOk}
        onCancel={handleConfirmCancel}
        width={300}
        closable={false}>
        <div className="z-ta-center">
          <h2>Are you sure?</h2>
          <p>You want to create booking</p>
        </div>
      </Modal>
    </Row>
  );
};

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CreateOrderPage);
