import React from 'react';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Form, Input, Row, Col } from 'antd';
import { connect } from "react-redux";
import {
  getAccess, login
} from "../../services/user.service";
import "./login.page.css";
import { toast } from "react-toastify";
const toastProps = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
}

const LoginPage = () => {
  const onFinish = async (values) => { 
    const req = await login(
      {
        email: values.username,
        password: values.password
      }
    );
    
    if (req.status === 200) { 
      localStorage.setItem('token', req?.data?.token); 
      localStorage.setItem('access', req?.data?.access);  
      window.location = '/'
    } else if (req.status === 404) {
      toast.error(req?.data?.message || "Error while uploading", toastProps);
    } else {
      toast.error("Error while updating", toastProps);
    }
  };
  return (
    <>
      <Row>
        <Col className="h-plr20 h-mt40 login-form">
          <Form
            name="normal_login"
            className="login-form"
            onFinish={onFinish}
          >
            <Form.Item
              name="username"
              rules={[{ required: true, message: 'Please input your Username!' }]}
            >
              <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[{ required: true, message: 'Please input your Password!' }]}
            >
              <Input
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Password"
              />
            </Form.Item>
            {/* <Form.Item>
              <Form.Item name="remember" valuePropName="checked" noStyle>
                <Checkbox>Remember me</Checkbox>
              </Form.Item>

              <a className="login-form-forgot" href="">
                Forgot password
              </a>
            </Form.Item> */}

            <Form.Item className='login-form-button-div'>
              <Button type="primary" htmlType="submit" className="login-form-button">
                Log in
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = ({ }) => {
  return {
  };
};
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
