const initialState = { 
};
const CommonReducer = (state = initialState, action) => {
  switch (action.type) { 
    default:
      return state;
  }
};

export { CommonReducer };
