const fetchAvailableBooking = (data) => {
  return async (dispatch) => { 
    dispatch({
      type: "UPDATE_BOOKING_LIST",
      data: data,
    });
    dispatch({ type: "BOOKING_LIST_LOADING_FALSE" });
  };
};

const fetchAvailableBookingLoadingTrue = (data) => {
  return async (dispatch) => {
    dispatch({ type: "BOOKING_LIST_LOADING_TRUE" });
  };
};

 

export {
  fetchAvailableBooking,
  fetchAvailableBookingLoadingTrue
};
