import React, { useState, useEffect } from "react";
import {
    Row,
    Col,
    DatePicker,
    Select
} from 'antd';
import { timeOptions } from '../../../helpers/time';
import moment from "moment";

const { Option } = Select;
const CustomDatetimePicker = ({ showTimeProps, disabledDate, onChange, value }) => {
    console.log('----------------------------value----------------------------')
    console.log(value)
    console.log('----------------------------value----------------------------')
    const [date, setDate] = useState('');
    const [time, setTime] = useState('');

    const triggerChange = (changedValue) => {
        const { date, time } = changedValue
        if (date && time) {
            const hour = Number(moment(time, ["h:mm A"]).format("HH"));
            const minute = Number(moment(time, ["h:mm A"]).format("mm"));
            const setObj = { hour, minute, second: 0 }
            const datetime = moment(date).set(setObj);
            onChange?.(datetime);
        }
    };

    const onSeletChange = (e) => {
        setTime(e)
        triggerChange({
            time: e,
            date
        });
    };

    const onDateChange = (e) => {
        setDate(e)
        triggerChange({
            date: e,
            time
        });
    };

    useEffect(() => {
        if (value) {
            setDate(value);
            const _time = moment(value).format('hh:mm A');
            setTime(_time)
        }
    }, [value]);

    return (
        <Row>
            <Col span={12}>
                <DatePicker
                    value={date}
                    showTime={showTimeProps}
                    onChange={onDateChange}
                    disabledDate={disabledDate}
                    format='DD-MM-YYYY'
                /></Col>
            <Col span={12}>
                <Select
                    defaultValue={timeOptions[0].label}
                    value={time}
                    placeholder="Select a time"
                    onChange={onSeletChange}  >
                    {timeOptions.map(o => <Option value={o.label}>{o.label}</Option>)}
                </Select>
            </Col>
        </Row>)
}

export default CustomDatetimePicker;
