export const timeOptions = [
//   {
//     label: "08:30 AM",
//     value: { hour: 8, minute: 30, second:0 },
//   },
  {
    label: "09:00 AM",
    value: { hour: 9, minute: 0, second:0 },
  },
  {
    label: "09:30 AM",
    value: { hour: 9, minute: 30, second:0 },
  },
  {
    label: "10:00 AM",
    value: { hour: 10, minute: 0, second:0 },
  },
  {
    label: "10:30 AM",
    value: { hour: 10, minute: 30, second:0 },
  },
  {
    label: "11:00 AM",
    value: { hour: 11, minute: 0, second:0 },
  },
  {
    label: "11:30 AM",
    value: { hour: 11, minute: 30, second:0 },
  },
  {
    label: "12:00 PM",
    value: { hour: 12, minute: 0, second:0 },
  },
  {
    label: "12:30 PM",
    value: { hour: 12, minute: 30, second:0 },
  },
  {
    label: "01:00 PM",
    value: { hour: 13, minute: 0, second:0 },
  },
  {
    label: "01:30 PM",
    value: { hour: 13, minute: 30, second:0 },
  },
  {
    label: "02:00 PM",
    value: { hour: 14, minute: 0, second:0 },
  },
  {
    label: "02:30 PM",
    value: { hour: 14, minute: 30, second:0 },
  },
  {
    label: "03:00 PM",
    value: { hour: 15, minute: 0, second:0 },
  },
  {
    label: "03:30 PM",
    value: { hour: 15, minute: 30, second:0 },
  },
  {
    label: "04:00 PM",
    value: { hour: 16, minute: 0, second:0 },
  },
  {
    label: "04:30 PM",
    value: { hour: 16, minute: 30, second:0 },
  },
  {
    label: "05:00 PM",
    value: { hour: 17, minute: 0, second:0 },
  },
  {
    label: "05:30 PM",
    value: { hour: 17, minute: 30, second:0 },
  },
  {
    label: "06:00 PM",
    value: { hour: 18, minute: 0, second:0 },
  },
  {
    label: "06:30 PM",
    value: { hour: 18, minute: 30, second:0 },
  },
  {
    label: "07:00 PM",
    value: { hour: 19, minute: 0, second:0 },
  },
  {
    label: "07:30 PM",
    value: { hour: 19, minute: 30, second:0 },
  },
  {
    label: "08:00 PM",
    value: { hour: 20, minute: 0, second:0 },
  },
  {
    label: "08:30 PM",
    value: { hour: 20, minute: 30, second:0 },
  },
  {
    label: "09:00 PM",
    value: { hour: 21, minute: 0, second:0 },
  },
  {
    label: "09:30 PM",
    value: { hour: 21, minute: 30, second:0 },
  },
];

export const checkDatetime = (_, value) => {
//   debugger;
  if (value) {
    return Promise.resolve();
  }
  return Promise.reject(new Error("Please input date time!"));
};
