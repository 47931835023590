const initialState = {
  pick: "",
  drop: "",
};
const SelectedTimeRangeReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_SELECTED_TIME_RANGE":
      return {
        ...state,
        pick: action.data.pick,
        drop: action.data.drop,
      };
    default:
      return state;
  }
};

export { SelectedTimeRangeReducer };
