import { axiosInstance } from "./axios.service";
import { toast } from "react-toastify";

function handleResponse(response) {
  return response;
}
const toasterProps = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};
function handleError(error) {
  console.log(error.response);
  if (error?.response?.status === 403) {
    toast.error('Login Session closed!', toasterProps);
    setTimeout(() => {
      localStorage.clear();
      window.location = '/'
    }, 2000)
  } else if (error?.response?.data?.error) {
    toast.error(error?.response?.data?.error, toasterProps);
  } else {
    toast.error("Internal server error!", toasterProps);
  }
  return Promise.reject(error);
}

export const getBookingList = async (params) => {
  const { page, from, to, status, search } = params;
  const limit = 10;
  let url = `/booking?page=${page}&limit=${limit}`;
  if (from) {
    url = url + `&from=${from}`
  }
  if (to) {
    url = url + `&to=${to}`
  }
  if (status) {
    url = url + `&status=${status}`
  }
  if (search) {
    url = url + `&search=${search}`
  }
  return await axiosInstance.get(`${url}`).then(handleResponse).catch(handleError);
};

export const addBooking = async (data) => {
  return await axiosInstance
    .post("booking", data)
    .then(handleResponse)
    .catch(handleError);
};

export const editBooking = async (data) => {
  return await axiosInstance
    .put("booking", data)
    .then(handleResponse)
    .catch(handleError);
};

export const deleteBooking = async (data) => {
  return await axiosInstance
    .delete("booking/" + data)
    .then(handleResponse)
    .catch(handleError);
};

export const getRevenueYearWise = async (year) => {
  return await axiosInstance.get("/bookings/revenue-year/" + year).then(handleResponse).catch(handleError);
};
