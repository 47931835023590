import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Row, Col, Select } from "antd";
import moment from "moment";
import {
  getRevenueYearWise
} from "../../services/booking.service";
import ColumnGraph from "../../components/charts/column/column"
const { Option } = Select;
const RevenuePage = () => {
  const [yearWiseData, setYearWiseData] = useState([]);
  const [year, setYear] = useState(2023);
  const getYearWise = async (year) => {
    const yearWise = await getRevenueYearWise(year);
    const formatedYW = yearWise?.data?.data.map((obj) => {
      const monthName = moment().month(obj.month - 1).format('MMMM');
      return {
        price: obj.price,
        month: monthName
      }
    })
    setYearWiseData(formatedYW);
  }
  const onLoad = async () => {
    await getYearWise(2023)
  }
  const onSeletChange = (year) => {
    setYear(year)
    getYearWise(year)
  };
  useEffect(() => {
    onLoad();
  }, []);
  return (
    <>
      <Row>
        <Col span={24} className="h-plr40">
          <h3>Year-wise</h3>
          <Select
            defaultValue={2023}
            value={year}
            placeholder="Select a time"
            onChange={onSeletChange}  >
            <Option value={2020}>2020</Option>
            <Option value={2021}>2021</Option>
            <Option value={2022}>2022</Option>
            <Option value={2023}>2023</Option>
            <Option value={2024}>2024</Option>
            <Option value={2025}>2025</Option>
          </Select>
          <ColumnGraph data={yearWiseData} />
        </Col>
      </Row >
    </>
  );
};

const mapStateToProps = ({ }) => {
  return {
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(RevenuePage); 
