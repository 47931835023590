import React from "react";
import { Row, Col } from "antd";
import moment from "moment";

const CompleteTable = ({ rideData, customerData }) => {
    const { pick, drop, bike, day, price, kms_limit } = rideData;
    const { customer_name, customer_phone, customer_alt_phone, customer_email, customer_dl } = customerData;
    return (
        <Row>
            <Col lg={10} sm={24} className="h-plr20">
                <h3>Customer Details</h3>
                <table className="complete-table">
                    <tr>
                        <td>Customer Name</td>
                        <td><b><mark>{customer_name}</mark></b></td>
                    </tr>
                    <tr>
                        <td>Phone</td>
                        <td>{customer_phone}</td>
                    </tr>
                    <tr>
                        <td>Alternative Phone</td>
                        <td>{customer_alt_phone}</td>
                    </tr>
                    <tr>
                        <td>Email</td>
                        <td>{customer_email}</td>
                    </tr>
                    <tr>
                        <td>DL No.</td>
                        <td>{customer_dl}</td>
                    </tr>
                </table>
            </Col>
            <Col lg={14} sm={24}  className="h-plr20">
                <h3>Ride Details</h3>
                <table className="complete-table">
                    <tr>
                        <td>Pick Up Date & Time</td>
                        <td>{pick && moment(pick).format('DD-MM-YYYY hh:mm A')}</td>
                    </tr>
                    <tr>
                        <td>Drop Off Date & Time</td>
                        <td>{drop && moment(drop).format('DD-MM-YYYY hh:mm A')}</td>
                    </tr>
                    <tr>
                        <td>Bike</td>
                        <td>{bike && `${bike.bike_name} (${bike.bike_number.toUpperCase()})`}</td>
                    </tr>
                    <tr>
                        <td>Total Days</td>
                        <td><b><mark>{day && day > 1 ? `${day} days` : `${day} day`}</mark></b></td>
                    </tr>
                    <tr>
                        <td>Total Price</td>
                        <td><b><mark>{price && `Rs. ${price}`}</mark></b></td>
                    </tr>
                    <tr>
                        <td>Total Kms limit</td>
                        <td><b><mark>{kms_limit && `${kms_limit} Kms`}</mark></b></td>
                    </tr>
                </table>
                <br />
                <h4>Bike details</h4>
                <table className="complete-table">
                    <tr>
                        <th>Bike</th>
                        <th>No.</th>
                        <th>Full Day</th>
                        <th>Half Day</th>
                        <th>Excess Kms Charge</th>
                    </tr>
                    <tr>
                        <td>{bike?.bike_name}</td>
                        <td>{bike && bike.bike_number?.toUpperCase()}</td>
                        <td>
                            <div>{`Charge: Rs. ${bike ? bike?.full_day_rate : 0}`}</div>
                            <div>{`Kms limit: ${bike ? bike?.full_day_limit : 0} Kms`}</div>
                        </td>
                        <td>
                            <div>{`Charge: Rs. ${bike ? bike?.half_day_rate : 0}`}</div>
                            <div>{`Kms limit: ${bike ? bike?.half_day_limit : 0} Kms`}</div>
                        </td>
                        <td>{`${bike ? bike?.excess_kms_charge : 0} Kms`}</td>
                    </tr>
                </table>
            </Col>
        </Row >)
}

export default CompleteTable;
