import React from "react";
import { connect } from "react-redux";
import { Row, Col, Descriptions } from "antd";
import moment from "moment";

const BikeTripInfoHeader = ({ bikeByStatus }) => {
    console.log(bikeByStatus,'bikeByStatus')
    const { blocked, available, on_trip } = bikeByStatus
    return (
        <Row>
            <Col span={24}>
                <Descriptions size="middle" column={5}>
                    <Descriptions.Item label="Date"><b>{moment().format('DD/MM/YYYY')}</b></Descriptions.Item>
                    <Descriptions.Item label="Bike Avail Today"><b>{available.length}</b></Descriptions.Item>
                    <Descriptions.Item label="On Trip"><b>{on_trip.length}</b></Descriptions.Item>
                    <Descriptions.Item label="Blocked"><b>{blocked.length}</b></Descriptions.Item>
                </Descriptions>
            </Col>
        </Row >)
}

const mapStateToProps = ({ bikeData: { bikeByStatus } }) => {
    return {
        bikeByStatus
    };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(BikeTripInfoHeader); 
