import React from "react";
import { Redirect, Route } from "react-router";
// import { useSelector } from "react-redux"; 
import { toast } from "react-toastify";

import Header from "./components/common/header/header";

const toasterProps = {
  position: "top-right",
  autoClose: 1000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

function PrivateRouteAdmin({ to, path, children }) {
  // const isAuth = useSelector((state) => state?.authReducer?.isAuth);
  const token = localStorage.getItem('token');
  const access = localStorage.getItem('access');
  const isAuth = token;
  if (isAuth && access === "admin") {
    return <Route exact path={path}>
      <Header />
      {children}
    </Route>
  } else {
    toast.error('Cannot access!', toasterProps);
    return <Redirect to={'/'}></Redirect>
  }
}

export default PrivateRouteAdmin;
