const initialState = {
  loading: false,
  bookings: [],
  total: 0
};
const BookingListReducer = (state = initialState, action) => {
  switch (action.type) {
    case "BOOKING_LIST_LOADING_TRUE":
      return { ...state, loading: true };
    case "BOOKING_LIST_LOADING_FALSE":
      return { ...state, loading: false };
    case "UPDATE_BOOKING_LIST":
      return {
        ...state,
        bookings: [...action.data.bookings],
        total: action.data.total
        // sort: action.data.sort,
      };
    default:
      return state;
  }
};

export { BookingListReducer };
